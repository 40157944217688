
import React from 'react';
// import { Switch,BrowserRouter as Router, Route  } from 'react-router-dom';
// import { Switch, Route } from "react-router-dom"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './Signup';
import Signin from './Signin';
import VerifyEmail from './VerifyEmail';
import Home from './Home';
import RegisterForm from './RegisterUser';
 import Login from './Login';
//  import AddStudent from './Student/AddStudent';
import UserForm from './UserForm';

// import RegisterStudent from './Student/RegisterStudent';
//https://tripsapi.akwaabatrips.com

function App() {
  return (
    <div >
     
     
        <Router>
        <Routes>

        <Route path='/m' element={ <UserForm />} />
        




        {/*
         <Route path='/registerstudent' element={ <RegisterStudent />} /> 
        */}


        <Route path='/home' element={ <Home/>} />
        {/* <Route path='/addstudent' element={ <AddStudent />} /> */}

        <Route path='/r' element={ <RegisterForm />} />

        <Route path='/g' element={ <Login/>} />
                  
        <Route path='/t' element={ <Signup />} />
        <Route path='/' element={ <Signin/>} />
        
        {/* <Route path='*' element={ <Signin/>} /> */}
        
        <Route path='/verifyemailsignin' element={ <VerifyEmail/>} />
 
        </Routes>
      </Router>


    </div>
  );
}

export default App;



// function App() {
//   return (
//     <Router>
//       <Switch>
//         <Route path="/signup" component={Signup} />
//         <Route path="/signin" component={Signin} />
//       </Switch>
//     </Router>
//   );
// }

// export default App;
