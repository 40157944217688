import React, { useState } from 'react';
import axios from 'axios';

import Swal from 'sweetalert2';
import { api } from './Api';
import { useNavigate } from "react-router-dom";

const Signin = () => {

  
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
   const history = useNavigate();

const [errorMessage, setErrorMessage] = useState('');

 
 

const handleSubmit = (e) => {
  e.preventDefault()
//   alert(process.env.REACT_APP_API_BASE_URL)
// 
//   if (!password || !email || (password == "" || password == null) || (email == "" || email == null)) {
    var k=10
    if (!k===10 ) {
    const Toast = Swal.mixin({
          toast: true,
          position: 'top-right',
            iconColor: 'red',
          customClass: {
              popup: 'colored-toast'
          },
          showConfirmButton: false,
          timer: 5500,
          timerProgressBar: true
      })
      Toast.fire({
          type: "error",
          icon: 'error',
          color: "red",
        //   background: 'red',
          title: 'email and password must be filled out! ',
          // text: 'Something went wrong!', email and password must be filled out
      })
 

  } else {
    //   setLoading(true);
// alert(122333)
      localStorage.setItem('thisuseremail', email);

      axios.post('https://tripsapi.akwaabatrips.com/api/login',  { email: email, password: password }
    //   api.post('/api/login', { email: email, password: password }
          ,
          {
              method: "POST",
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Authorization': 'application/json'

              }
          },
      )
          .then(res => {

// var email;
              // setLoading(true);
              // setLoading_err(false);
            //   localStorage.setItem('currentuseremail', email);
            if (res.data.message) {

              //  console.log(res.data.token);
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('jwtToken', res.data.token);
              axios.defaults.headers.common['Authorization'] = 'Bearer' + res.data.token
             setTimeout(function () { window.location = "/home"; }, 3000);
// 
              //document.getElementById("hideloader").style.display = 'block';


              const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                iconColor: 'green',
                customClass: {
                  popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 5500,
                timerProgressBar: true
              })
              Toast.fire({
                type: "success",
                icon: 'success',
                color: "green",
                //  background: 'red',
                title: ' done successful ! '  ,
                // text: 'Something went wrong!', email and password must be filled out
                // footer: '<a href="#" style="color:blue;font-weight:bold"> “Account recovery requests can be delayed for a few hours or a number of days, depending on a variety of risk factors.</a>'
    
              })

              }

          })
          .catch(error => {
            console.error(error);
    
            if (error.response) {
              const { status } = error.response;
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                iconColor: 'red',
                customClass: {
                  popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 5500,
                timerProgressBar: true
              });
    
              Toast.fire({
                icon: 'error',
                title: 'Error!   : ' + error.response.data.message,
              });
            }
    
    
          });
      }
    };
    










return (
  <div>

  <div class="container mt-5">
      <div class="row justify-content-center">
          <div class="col-md-6">
              <div class="card">
                  <div class="card-header text-center">
                      <h4>Login</h4>
                      {message && <p class="alert alert-danger alert-dismissible fade show">{message}</p>}
                  </div>
                  <div class="card-body">
                      <form>
                          <div class="mb-3">
                              <label for="email" class="form-label">Email address</label>
                              <input type="email"  
                              
                              value={email} placeholder="Email"
                              onChange={(e) => { setEmail(e.target.value) }}
                
                              
                              class="form-control" id="email" required />
                          </div>
                          <div class="mb-3">
                              <label for="password" class="form-label">Password</label>
                              <input type="password" class="form-control" id="password"
                                   value={password} placeholder="password"
                                   onChange={(e) => { setPassword(e.target.value) }}
                              
                              required />
                          </div>
                          <div class="d-grid gap-2">
                              <button type="submit"
                              
                              onClick={e=>handleSubmit(e)}
                              class="btn btn-primary">Login</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>


       
  </div>

    
   
  );
}

export default Signin;
