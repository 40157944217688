import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Logout from './Logout';

function Home() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [Thetoken, setThetoken] = useState('');














  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get('https://tripsapi.akwaabatrips.com/api/thefinduser', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        setUser(response.data.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            setError('Your account is not active or validated!');
             
       setTimeout(function () { window.location = "/"; }, 1000);

          } else {
            setError('User validation error!');
             
       setTimeout(function () { window.location = "/"; }, 1000);

          }
        }
      });
    }
  }, []);



  useEffect(() => {
    const token = localStorage.getItem('token');
// alert(token )
setThetoken(token)

   },[])



  if (error) {
    return <p>{error}</p>;
  }

  if (!user) {
    return <p>Loading...

      


    </p>;
  }




  return (
    <div>
      <h2>User Profile</h2>
      <p>Email: {user.email}</p>
      <p>Username: {user.name}</p>
      {/* Display other user data as needed */}

<p> {Thetoken} </p>

      <p>
        <Logout />
      </p>
    </div>
  );
}
 


export default Home;



















// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// function Home() {
//   const [userData, setUserData] = useState(null);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const token = localStorage.getItem('token');
        
//         const response = await axios.get('https://tripsapi.akwaabatrips.com/api/profile', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         setUserData(response.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchUserData();
//   }, []);

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div>
//       <h1>Welcome back, {userData.name}!</h1>
//       <p>Your email is: {userData.email}</p>
//     </div>
//   );
// }

// export default Home;
