import React, { useState } from 'react';
import axios from 'axios';

import Swal from 'sweetalert2';
import Joi from 'joi';


function Signup() {
  // const [Firstname, setFirstName] = useState('');
  // const [Lastname, setLastName] = useState('');
  // const [mobilephonenumber , setMobilephonenumber] = useState('');
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobilePhoneNumber , setMobilePhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
//   const handleSubmit = async (event) => {
//     event.preventDefault();

// // alert(12345677)

//     const user = {
//       name,
//       email,
//       password
//     };

//     await axios.post('https://tripsapi.akwaabatrips.com/api/signup', user);
//     setFirstName('');
//     setEmail('');
//     setPassword('');
//   };




const handleSubmit = async (e) => {
  e.preventDefault();

  // const registrationSchema = Joi.object({
  //   name: Joi.string()
  //     .pattern(new RegExp('^[a-zA-Z0-9\\s.-]+$'))
  //     .required()
  //     .messages({
  //       'string.pattern.base': 'Name can only contain letters, numbers, spaces, dots, and dashes'
  //     }),
  //   email: Joi.string().email().required(),
  //   password: Joi.string()
  //     .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!#.])[A-Za-z\\d$@$!%*?&.]{8,20}$'))
  //     .required()
  // });

  const k = 10;

  if (k !== 10) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'red',
      customClass: {
        popup: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 5500,
      timerProgressBar: true
    });

    Toast.fire({
      icon: 'error',
      title: 'Form validation error!',
    });
  } else {
    // const { error } = registrationSchema.validate({ name, email, password });

    // if (error) {
    //   setError(error.details[0].message);
    //   return;
    // }

    // setError('');

    axios.post('https://tripsapi.akwaabatrips.com/api/register', {
      firstname: firstName,
      name: firstName,
      lastname:lastName,
      email: email,
      mobilephonenumber: mobilePhoneNumber,
      password: password
    })
      .then(res => {
        // if (res.status  === 201) {
          if (res.data.message) {

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'green',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
          });

          Toast.fire({
            icon: 'success',
            title: 'Registration successful!',
          });
        }
      })
      .catch(error => {
        console.error(error);

        if (error.response) {
          const { status } = error.response;
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'red',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
          });

          Toast.fire({
            icon: 'error',
            title: 'Error!   : ' + error.response.data.message,
          });
        }


      });
  }
};

 
 


  return (
    <div className="form-container">
    <form>
      <label>
        FirstName:
        <input 
         type="text" className='form-control' value={firstName} onChange={e => setFirstName(e.target.value)} required />
      </label>
      <label>
        LastName:
        <input 
         type="text" className='form-control' value={lastName} onChange={e => setLastName(e.target.value)} required />
      </label>
      <label>
        Mobile Phone Number:
        <input 
         type="tel" className='form-control' value={mobilePhoneNumber} onChange={e => setMobilePhoneNumber(e.target.value)} required />
      </label>
      <label>
        Email:
        <input type="email" className='form-control'  value={email} onChange={e => setEmail(e.target.value)} required />
      </label>
      <label>
        Password:
        <input type="text" className='form-control'
          value={password} onChange={e => setPassword(e.target.value)} required />
      </label>
      <input type="submit" className='btn btn-primary' value="Submit" onClick={handleSubmit} />
    </form>
  </div>
  );
}

export default Signup;
