import axios from 'axios';

 
// export const api = axios.create({
//     // baseURL: "http://localhost:7010",
//     baseURL: process.env.REACT_APP_API_BASE_URL,
//     interceptors: {
//       response: (response) => {
//         return response;
//       },
//       error: (error) => {
//         // Handle error here
//         return Promise.reject(error);
//       },
//     },
//   });
   

// Create a new Axios instance
export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add a request interceptor to include the authentication token in every request
api.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${"Your token here"}`;
    return config;
}, error => {
    // If there's an error in the request, reject the promise
    return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(response => {
    // Just return the response
    return response;
}, error => {
    // If the error status is 401 (Unauthorized), do something
    if (error.response.status === 401) {
        console.log("Unauthorized request. Please login again.");
    }

    // If the error status is 500 (Internal Server Error), do something else
    if (error.response.status === 500) {
        console.log("There was a problem with the server. Please try again later.");
    }

    // Reject the promise with the error
    return Promise.reject(error);
});

// export default api;  
