import React from 'react';
import axios from 'axios';

function Logout() {
  const logout = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // Send a request to your server's logout endpoint
        await axios.post('https://tripsapi.akwaabatrips.com/api/logout-endpoint', {}, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // Remove the token from local storage
        localStorage.removeItem('token');
        // Optionally, redirect the user to the login page     
        window.location.href = '/';
      } catch (error) {
        console.error('Error during logout:', error);
      }
    }
  };



//   const handleLogout = (e) => {
//     e.preventDefault(e)

//    // getAreaupdatedetails();


    
//     setTimeout(function () {  

//     api.post('/api/logout')
//         .then(res => {
//             console.log(res.statusText)
//             localStorage.removeItem('jwtToken')
//            delete axios.defaults.headers.common['Authorization']
//        //     setUser({ auth: false, name: '' })
//        window.localStorage.clear();
//    window.location.href='/'
//         })
//         .catch(err => console.log(err))
//       }, 1000);
// }




  return (
    <button className='btn btn-danger' onClick={logout}>Logout</button>
  );
}

export default Logout;
