import React, { useState } from 'react';
import axios from 'axios';

function RegisterForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = { name, email, password };
    try {
      const response = await axios.post('/api/register', user);
      setMessage(response.data.message);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div>
             
             <div className='col-md-4 mx-auto'>
             <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" className='form-control'
           value={name} onChange={e => setName(e.target.value)} required />
        </label>
        <br/>
        <label>
          Email:
          <input type="email" className='form-control'
           value={email} onChange={e => setEmail(e.target.value)} required />
        </label>
        <br/>
        <label>
          Password:
          <input type="text" className='form-control' 
          value={password} onChange={e => setPassword(e.target.value)} required />
        </label>
        <br/>       <br/>
        <input type="submit" value="Register" className='btn btn-success' />

      </form>
      <br/>
      {error && <div>Error: {error}</div>}
      {message && <div>Message: {message}</div>}

             </div>

    </div>
  );
}

export default RegisterForm;
