import React, { useState } from 'react';
import axios from 'axios';
// import { GoogleLogin } from 'react-google-login';
// import { GoogleLogin } from 'react-google-login';


const Login = () => { 

  const [Email,setEmail]=useState("")
const [Password,setPassword]=useState("")

 
  return (
    <div>
    


    <form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Email address</label>
    <input   value={Email} onChange={(e) => setEmail(e.target.value)}  type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Password</label>
    <input  value={Password} onChange={(e) => setPassword(e.target.value)}   type="text" class="form-control" id="exampleInputPassword1" />
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div>
  <button type="submit" class="btn btn-primary">Submit</button>
</form>

 
    </div>
  );
};

export default Login;
