 
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Form, Button, FormControl } from 'react-bootstrap';
import axios from 'axios';
import Techlogo from './Techlogo.png'

import './Verifyemailcss.css'

import { useLocation } from 'react-router-dom';

import Swal from 'sweetalert2';
import { api } from './Api';

function VerifyEmail() {
    const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [Getemail, setGetEmail] = useState(query.get('email') || "");


  const locationstoken = useLocation();
  const querytoken = new URLSearchParams(locationstoken.search);
  const [Getemailtoken, setGetEmailtoken] = useState(querytoken.get('token') || "");


  const [verificationCode, setVerificationCode] = useState("");
   const [email, setEmail] = useState('');

useEffect(() =>{
    // setTimeout(function () {
        // const inputText = Getemail;
        // const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
        // const extractedEmail = inputText.match(emailRegex);
        // if (extractedEmail) {

         //     setEmail(setGetEmail(extractedEmail[0]));
        //      ;
    
        // alert(email)
        // } else {
        //   setEmail('');
        // } 
        // }, 1000);


        // setTimeout(function () {
        //     alert(Getemail);
        // }, 2000);
 

},[])
    // const [email, setEmail] = useState("");
    // const [verificationCode, setVerificationCode] = useState("");

  
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://tripsapi.akwaabatrips.com/api/emailverificationcodelog',
         {verificationcode:verificationCode , getemailtoken:Getemailtoken })
          .then((res) => {
   
            //   alert(res.data);
              
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                iconColor: 'green',
                customClass: {
                  popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 5500,
                timerProgressBar: true
              })
              Toast.fire({
                type: "success",
                icon: 'success',
                color: "green",
                //  background: 'red',
                title: 'done successful! '  ,
                // text: 'Something went wrong!', email and password must be filled out
                // footer: '<a href="#" style="color:blue;font-weight:bold"> “Account recovery requests can be delayed for a few hours or a number of days, depending on a variety of risk factors.</a>'
    
              })




          })
          .catch((error) => {
            // console.error(err);
          
              if (error.response) {
                const { status } = error.response;
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-right',
                  iconColor: 'red',
                  customClass: {
                    popup: 'colored-toast'
                  },
                  showConfirmButton: false,
                  timer: 5500,
                  timerProgressBar: true
                });
      
                Toast.fire({
                  icon: 'error',
                  title: 'Error!   : ' + error.response.data.message,
                });
              }
      
      
            });
 
        
      };
  


  return (
    <div>  

     {/*
  
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <img style={{width:"40px",height:"40px"}} src={Techlogo} alt="Company Logo" />
    </a>
     
  </nav>
   */}



     <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#">
          <img
           src={Techlogo}
           style={{width:"40px",height:"40px"}} 
            className="d-inline-block align-top"
            alt="Company Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="#">Home</Nav.Link>
            <Nav.Link href="#">Contact Us</Nav.Link>
            <Nav.Link href="#">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      
      <div style={{position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%'}}>
     
        <h2 className="text-center">Email Verification</h2>
        <h4>This is {Getemailtoken}</h4>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formVerificationCode " className= "mb-12">
            <Form.Label>Verification Code</Form.Label>
            <Form.Control type="text" placeholder="Enter verification code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} required/>
          </Form.Group  >
          <Button variant="primary" type="submit" style={{marginTop: '10px'}}>Verify</Button>
        </Form>
      </div>






{/* 
<!-- Email Verification Form --> */}
   



    </div>
  )
}

export default VerifyEmail